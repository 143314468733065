import React from 'react'
import { StyledPointer, StyledCtasContainer, StyledStarsIcon, StyledButton, StyledCtaText, StyledTitle, StyledSubText, StyledSmallCta , StyledCtaFlagImage, StyledCtaImage } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

export default function SmallContentCtaListing({
    module
}) {
    if(module && module.signUpSteps) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            isOverflowHidden={true}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            innerSpacing={true}
            data-name="small-content-cta-listing"
        >
            <StyledContainer> 
                {module.title && 
                    <StyledTitle
                        fontSize={{ mobile: 'lg', desktop: 'xl' }}
                    >
                        {module.title}
                    </StyledTitle>
                } 
                
                {module.textTop && 
                    <StyledSubText 
                        dangerouslySetInnerHTML={{ __html: module.textTop }}
                    />
                }

                <StyledCtasContainer>
                    {module.signUpSteps.map((contentCta, i) => (
                        <SmallContentCta 
                            contentCta={contentCta}
                            module={module}
                            isFirstItem={i === 0}
                            isOddItem={i % 2}
                            isLastItem={i === module.signUpSteps.length - 1}
                            key={contentCta.text + i + 'small-content-cta'}
                        />
                    ))}
                </StyledCtasContainer>

     
        
            </StyledContainer>     

            {module.textBottom && 
                <StyledContainer> 
                    <StyledSubText 
                        dangerouslySetInnerHTML={{ __html: module.textBottom }}
                        isBottomText={true}
                    />
                </StyledContainer>
            }

        </StyledModuleContainer>
    )
    return null;
}

export const SmallContentCta = ({ module, contentCta, isFirstItem, isOddItem, isLastItem }) => {
    const { numberIcon, featuredImage, text, buttonLink } = contentCta;

    return (
        <StyledSmallCta
            $isRightAligned={isOddItem}
        >
            {numberIcon && 
                <StyledCtaFlagImage 
                    useGatsbyImage={false}
                    alt={numberIcon.altText}
                    imageFile={numberIcon.imageFile}
                />
            }
        
            {featuredImage && 
                <StyledCtaImage 
                    imageFile={featuredImage.imageFile}
                    alt={featuredImage.altText} 
                />
            }
            
            {text && 
                <StyledCtaText 
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            }
        
            {buttonLink && 
                <StyledButton 
                    href={buttonLink.url} 
                    buttonOptions={module.buttonOptions}
                    fontSize={'sm'} 
                >
                    {buttonLink.title}
                </StyledButton>
            }
            
            {module.enableStars && 
                <StyledStarsIcon 
                    color={module.starsColor} 
                    $isRightAligned={isOddItem} 
                />
            }
            
            {!isLastItem && module.enablePointer ?
                <StyledPointer 
                    $isFirstPointer={isFirstItem}
                    $isPointedLeft={isOddItem}
                    $isRightAligned={isOddItem} 
                    color={module.pointerColor} 
                />
            : null}
        
        </StyledSmallCta>
    )
}