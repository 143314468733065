import styled, { css } from 'styled-components';
import { theme } from '@theme';

import Stars from '@commons/stars';
import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 900;
    margin: 0 0 20px 0;
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 30px;
    }
`

export const StyledSubText = styled.div`
    font-size: 1.4rem;
    text-align: center;
    margin: 15px 0 0 0;
    /* margin: 0 0 10px 0;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 30px;
    } */

    ${theme.mediaQuerys.smUp} {
        margin: 0px;
    }

    ${theme.mediaQuerys.mdUp} {
        font-size: 1.6rem;
    }

    p {
        margin: 0;
    }

    /* ${props => props.isBottomText && css`
        position: relative;
        

        ${theme.mediaQuerys.smUp} {
            bottom: -30px;
        }

        ${theme.mediaQuerys.mdUp} {
            bottom: -40px;
        }
    `} */
`

export const StyledCtasContainer = styled.div`

    ${theme.mediaQuerys.smUp} {
        margin: 0 auto;
        padding: 0 30px;
        max-width: 750px;
    }

    ${theme.mediaQuerys.mdUp} {
        padding: 30px 30px 0 30px;
        max-width: 1000px;
    }
`

export const StyledSmallCta = styled.div`
    position: relative;
    z-index: 0;
    text-align: center;
    padding: 55px 0 50px 0;
    max-width: 320px;
    margin: 0 auto;

    ${theme.mediaQuerys.smUp} {
        margin-left: 0; 
        margin-bottom: 30px;
    }

    &:nth-of-type(1n + 2) {

        ${theme.mediaQuerys.mdUp} {
            margin-top: -175px;
        }
    }

    ${props => props.$isRightAligned && css`
        
        ${theme.mediaQuerys.smUp} {
            margin-left: auto;
            margin-right: 0; 
        }

       
    `}
`

// currently used for numbers
export const StyledCtaFlagImage = styled(WrImage)`
    position: absolute !important; // overides gatsby image "position: relative"
    top: 0px;
    left: -15px;
    width: 75px;
`

export const StyledCtaImage = styled(WrImage)`
    width: 60%;
    margin: 0 auto;
`

export const StyledCtaText = styled.div`
    font-size: 2rem; // was 2.2rem
    line-height: 25px; // was 27px
    font-weight: 900;
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    font-family: ${theme.fonts.header};
    padding: 35px 20px 55px 20px;

    p {
        margin: 0;
    }
`

export const StyledStarsIcon = styled(Stars)`
    width: 200px;
    position: absolute;
    left: -30px;
    bottom: 0px;
    z-index: -1;

    ${props => props.$isRightAligned && css`
        left: unset;
        right: -30px;
    `}
`

export const StyledButton = styled(WrButton)`
    margin-top: -30px;
`

export const StyledPointer = styled(WrPointer)`
    display: none;

    ${theme.mediaQuerys.smUp} {
        display: block;
        position: absolute;
        bottom: 50px;
        height: 150px;   
    }

    ${theme.mediaQuerys.mdUp} {
        height: 160px;
    }

    ${props => props.$isFirstPointer && css`
    
        ${theme.mediaQuerys.mdUp} {
            transform: rotate(-100deg);
            bottom: 150px;
            right: -210px;
        }
    `}  

    ${props => !props.$isPointedLeft && css`
        right: -200px; 
        transform: rotate(-55deg);
    `}

    ${props => !props.$isPointedLeft && !props.$isFirstPointer ? css`

        ${theme.mediaQuerys.mdUp} {
            transform: rotate(-10deg) scaleX(-1);
            bottom: -150px;
            right: -210px;
        }
    `: null}

    ${props => props.$isPointedLeft && css`
        left: -200px;
        transform: scaleX(-1) rotate(-55deg);

        ${theme.mediaQuerys.mdUp} {
            transform:  rotate(10deg);
            bottom: -150px;
            left: -175px;
        }
    `}
`